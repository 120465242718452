export default [{
  label: 'Razão social', field: 'razaoSocial', type: 'custom',
},
{ label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'tamanho-cnpj' },
{ label: 'RBA- Receita bruta acumulada', field: 'rba', tdClass: 'col-2 text-right' },
{
  label: '3.600.000,00 % Sublimite', field: 'rbaSublimite', tdClass: 'col-tamanho-situacao text-center', type: 'custom',
},
{
  label: '4.800.000,00 % Limite', field: 'rbaLimite', tdClass: 'col-tamanho-situacao text-center', type: 'custom',
},
{ label: 'Competência', field: 'competencia' },
{ label: 'Consultado em', field: 'consultadoEm' }]
