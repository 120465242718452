import axios from '@axios'

const PREFIX = 'sublimites'

export default {

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getURLSublimite(id) {
    return axios.get(`${PREFIX}/${id}/url`)
  },

  baixaEmLote(filtros, nomeArquivo) {
    return axios.post(`${PREFIX}/baixa-lotes?nomeArquivo=${nomeArquivo}`, filtros)
  },
}
